import React from "react";
import Layout from "~/components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { graphql } from "gatsby";
import { Link } from "gatsby";
import ReactMarkdown from "react-markdown";
import Image from "~/components/image";
import Card from "~/components/styled/card";
import Moment from "react-moment";

const Author = ({ data }) => {
	const author = data.strapiAuthor;

	return (
		<Layout>
			<div className="">
				<GatsbySeo
					title={author.name}
					description="Setting the expectations right for your upcoming tour."
				/>
				<div className="my-20">
					<h1 className="font-bold">About {author.name}</h1>
					<p className="font-light text-xl text-primary max-w-3xl mb-14">
						From {author.location}
					</p>
				</div>

				<section>
					<ReactMarkdown className="prose " children={author.about} />
					{/* <div>
						<div className="flex flex-col lg:flex-row items-center lg:justify-between py-20">
							<div className="lg:w-1/2 lg:pr-8">
								{author.image && (
									<Image
										//alt="Itinerary Image"
										className="mx-auto lg:mx-0 lg:w-full rounded-lg shadow-lg "
										image={author.image}
										loading="lazy"
									/>
								)}
							</div>
							<div className="lg:w-1/2 lg:pl-6">
								<ReactMarkdown
									className="prose prose-sm max-w-full"
									children={author.about}
								/>
							</div>
						</div>
					</div> */}
				</section>
				<section className="mt-20">
					<h2 className="my-10">Recent stories from {author.name}</h2>
					<div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-20">
						{author.articles.map((node) => {
							return (
								<Card>
									<Link to={`/blog/${node.slug}`}>
										<Image
											alt="Category Image"
											className="rounded-t-md transform border-gray-200	 border-b"
											image={node.image}
											loading="lazy"
										/>

										<div className="px-4 py-2 ">
											<div className="mb-0.5">
												<p className="font-medium capitalize hover:text-primary">
													{node.title}
												</p>
											</div>
											{/* <div className="flex mb-4">
												<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
													{node.category.name}
												</p>
											</div> */}

											<div>
												<p className="text-xs text-gray-500 capitalize">
													<Moment format="MMM Do YYYY">{node.published_at}</Moment>
												</p>
											</div>
										</div>
									</Link>
								</Card>
							);
						})}
					</div>
				</section>
			</div>
		</Layout>
	);
};

export const query = graphql`
	query AuthorQuery($slug: String!) {
		strapiAuthor(slug: { eq: $slug }) {
			name
			slug
			about
			location
			articles {
				title
				slug
				published_at
				image {
					localFile {
						publicURL
						childImageSharp {
							gatsbyImageData(aspectRatio: 1.8, layout: FULL_WIDTH)
						}
					}
				}
			}
			image {
				localFile {
					childImageSharp {
						gatsbyImageData(placeholder: BLURRED, aspectRatio: 1.3)
					}
				}
			}
		}
	}
`;

export default Author;
